import React from 'react';
import { ContentWrapperContainer } from './index.styles';

const ContentWrapper: React.FC<{
  justify?: string;
}> = (props) => (
  <>
    <ContentWrapperContainer justify={props.justify ? props.justify : 'center'}>
      {props.children}
    </ContentWrapperContainer>
  </>
);
export default ContentWrapper;
