import { Notification } from '@l_ine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../../redux/app/actions';
import { AlertProps } from './index.d';

const Alert: React.FC<AlertProps> = () => {
  const dispatch = useDispatch();
  const { snackbar } = useSelector((state: any) => ({
    snackbar: state.AppReducer.snackbar,
  }));

  const { activated, time, title, message, variant } = snackbar;

  const handleClose = () => {
    dispatch(
      appActions.toggleSnackBar({
        message: '',
        activated: false,
        time: 3000,
        variant: 'success',
      })
    );
  };

  return (
    <Notification
      data-testid={`notification-${variant}`}
      variant={variant || 'success'}
      autoHideDuration={time}
      title={title}
      message={message}
      open={activated}
      floating
      showCloseButton
      positionX='right'
      positionY='bottom'
      onClose={handleClose}
    />
  );
};

export default React.memo(Alert);
