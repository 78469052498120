import React from 'react';

import { CircularProgress } from '@l_ine/core';
import * as styled from './index.style';

const Loading = () => {
  return (
    <styled.Wrapper>
      <styled.PaperLoading>
        <CircularProgress color='primary' size={68} />
      </styled.PaperLoading>
    </styled.Wrapper>
  );
};

export default React.memo(Loading);
