export const colorPalette = {
  white: "rgb(255,255,255)",
  black: "rgb(0,0,0)",
  grey: {
    light: "rgb(110, 127, 128)",
    main: "rgb(58, 63, 65)",
  },
  primary: {
    light: "rgb(142, 194, 242)",
    medium: "rgb(4, 91, 168)",
    dark: "rgb(19, 44, 112)",
  },
  secondary: {
    light: "rgb(247, 247, 247)",
    medium: "rgb(235, 236, 240)",
    dark: "rgb(190, 197, 207)",
  },
  success: {
    light: "rgb(214, 238, 188)",
    medium: "rgb(168, 207, 70)",
    dark: "rgb(81, 129, 51)",
  },
  danger: {
    light: "rgb(251, 216, 216)",
    medium: "rgb(239, 72, 41)",
    dark: "rgb(109, 30, 42)",
  },
  warning: {
    light: "rgb(248, 227, 184)",
    medium: "rgb(255, 171, 0)",
    dark: "rgb(163, 71, 3)",
  },
  font: {
    light: "rgb(244, 250, 255)",
    medium: "rgb(61, 80, 108)",
    dark: "rgb(18, 23, 41)",
  },
  background: "rgb(255, 255, 255)",
  form: {
    border: "rgb(216,220,228)",
    background: "rgb(255,255,255)",
  },
  widgets: {
    background: "rgb(255,255,255)",
    number: "rgb(77,90,104)",
    label: "rgb(131,155,179)",
  },
  loading: {
    background: "rgba(0, 0, 0, 0.5)",
  },
  stepperCell: {
    icon: "rgba(123,127,131,0.4)",
    currentBackground: "rgba(239, 246, 254, 0.8)",
    textColor: "rgb(61,80,108)",
  },
  selector: {
    background: "rgb(18, 23, 41)",
    normal: "rgb(236,204,38)",
    complemento: "rgb(209,130,48)",
    anulacao: "rgb(231,67,67)",
    substituto: "rgb(39,200,41)",
  },
  badge: {
    bgColor: "rgb(59,202,120)",
  },
  tableRow: {
    border: "rgb(240, 246, 255)",
  },
  button: {
    textPrimary: {
      bgColor: "rgb(63,127,233)",
      color: "rgb(63,127,233)",
    },
    outPrimary: {
      color: "rgb(91, 148, 236)",
      borColor: "rgb(4, 91, 168)",
    },
    contPrimary: {
      bgColor: "rgb(4, 91, 168)",
    },
    outSecondary: {
      color: "rgb(88,107,141)",
      borColor: "rgb(4, 91, 168)",
    },
  },
  text: {
    primary: "rgb(0, 0, 0)",
    secondary: "rgb(255,255,255)",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)",
  },
  wrapper: "rgba(0, 0, 0, 0.6)",
  border: "1px solid #d6dfec",
  expansionTab: {
    bgColor: "rgb(255,255,255)",
  },
  $404: {
    btnTxtColor: "rgb(255,255,255)",
    menuHolderTxt: "rgb(255,255,255)",
    aColor: "rgb(255,255,255)",
  },
  $500: {
    btnTxtColor: "rgb(255,255,255)",
    menuHolderTxt: "rgb(255,255,255)",
    aColor: "rgb(255,255,255)",
  },
  forgotPass: {
    wrapper: "rgba(0,0,0,0.6)",
    bgColor: "rgb(255,255,255)",
  },
  resPass: {
    wrapper: "rgba(0,0,0,0.6)",
    bgColor: "rgb(255,255,255)",
  },
  signup: {
    wrapper: "rgba(0,0,0,0.6)",
    bgColor: "rgb(255,255,255)",
  },
  drawer: {
    background: {
      primary: "rgb(19, 44, 112)",
      secondary: "rgb(4, 91, 168)",
      third: "rgba(38, 135, 234, 0.2)",
    },
    button: {
      bgColor: "rgb(168, 207, 70)",
    },
  },
  config: {
    themeDefault: {
      btnColor: "rgb(255,255,255)",
      txtColor: "rgb(50,50,50)",
    },
    topbar: {
      btnColor: "rgb(51,51,51)",
    },
    sidebar: {
      btnColor: "rgb(255,255,255)",
      menuIColor: "rgb(255,255,255)",
      menuIHoverColor: "rgb(255,255,255)",
    },
  },
  themeSwitcher: {
    bgColor: "rgb(255,255,255)",
    titleColor: "rgb(255,255,255)",
    toggleBtn: {
      bgColor: "rgb(255,255,255)",
    },
    purchaseBtn: {
      bgColor: "rgb(255,255,255)",
      txtColor: "rgb(255,255,255)",
    },
  },
  card: {
    bgColor: "rgb(255,255,255)",
  },
  vCard: {
    bgColor: "rgb(255,255,255)",
  },
  link: {
    bgColor: "rgb(73, 110, 219)",
  },
  report: {
    bgColor: "rgb(255,255,255)",
  },
  sale: {
    bgColor: "rgb(255,255,255)",
  },
  widgetBox: {
    bgColor: "rgb(255,255,255)",
  },
  muiButton: {
    txtPrimaryColor: "rgb(255,255,255)",
    txtContainPrimaryColor: "rgb(255,255,255)",
  },
  input: {
    label: "rgb(97, 113, 130)",
    text: {
      enabled: "#323a48",
      disabled: "rgb(148,157,163)",
    },
    border: {
      enabled: "rgb(0,0,0)",
      disabled: "rgb(190,197,207)",
    },
    background: {
      disabled: "rgba(0,0,0,0.05)",
    },
  },
  avatar: {
    background: "rgb(184,192,212)",
  },
  tableChips: {
    incomplete: "rgb(252, 216, 128)",
    notSent: "rgb(224, 224, 224)",
    sent: "rgb(129, 162, 210)",
    authorized: "rgb(167, 203, 130)",
    canceled: "rgb(184, 129, 129)",
    denied: "#eee",
    completed: "rgb(151, 199, 204)",
  },
  averbIcon: {
    gray: "rgba(18,23,41,0.7)",
  },
};
