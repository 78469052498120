import React, { useRef } from 'react';

import { Paper, Typography } from '@l_ine/core';
import * as styled from './index.style';
import Router from 'next/router';

import { CardProps } from './index.d';

const Card: React.FC<CardProps> = ({ page }) => {
  const linksRef = useRef<any>(null);

  const handleClick = (e: any) => {
    if (linksRef.current && linksRef?.current?.contains(e.target)) return;
    Router.push(`/config/${page.url}`);
  };

  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Paper data-testid={page.dataTestid} shadow={300} style={{ borderRadius: 8 }}>
        <styled.Wrapper>
          <styled.Icon icon={page.icon} size='48px' />
          <styled.TextContainer>
            <Typography variant='titleXS' align='center'>
              {page.title}
            </Typography>
          </styled.TextContainer>
        </styled.Wrapper>
      </Paper>
    </div>
  );
};

export default Card;
