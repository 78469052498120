import styled from 'styled-components';
import { colorPalette } from '@l_ine/core';
import { Grid, LIcon } from '@l_ine/core';

export const Container = styled.button`
  padding: 0 !important;
  outline: none !important;
  text-align: start;
  border: 1px solid ${colorPalette.neutral[50]};
  border-radius: 6px;
  background-color: ${colorPalette.neutral[10]};
  display: flex;
  align-items: center;
  height: 6.5rem;
  width: 100% !important;
  &:hover {
    background-color: ${colorPalette.neutral[30]};
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  justify-content: center;
  width: 110px;
  height: 110px;
`;

export const Icon = styled(LIcon)`
  margin: 0px 8px;
`;

export const DescriptionGrid = styled(Grid)`
  padding: 2px 0px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
