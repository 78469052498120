import React from 'react';

//UI
import * as styled from './index.style';
import { Button, Typography, LIcon, Breadcrumbs, Tooltip } from '@l_ine/core';
import { isArray } from 'lodash';
import Link from 'next/link';
import { lineChevronLeft } from '@l_ine/regular-svg-icons';
import { useRouter } from 'next/router';
import { usePathname } from '@l_ine/hooks';
import { camelize } from '../../utils/AllUtils';

interface PageHeaderProps {
  title: string | React.ReactNode;
  breadcrumbs?: string | JSX.Element | { label: string; link?: string }[];
  primaryButtonTitle?: string | null | boolean;
  primaryButtonClick?(e?: any): void;
  primaryButtonIcon?: any;
  primaryButtonEndIcon?: any;
  secondaryButtonTitle?: string | null | boolean;
  secondaryButtonClick?(): void;
  secondaryButtonIcon?: any;
  terciaryButtonClick?(): void;
  terciaryButtonIcon?: any;
  terciaryButtonTitle?: string;
  backButton?: boolean;
  primaryButtonDisabled?: boolean | null;
}

function PageHeader(props: PageHeaderProps) {
  const router = useRouter();
  const pathname = usePathname({ router });

  const {
    breadcrumbs,
    title,
    primaryButtonClick,
    primaryButtonIcon,
    primaryButtonDisabled,
    primaryButtonTitle,
    secondaryButtonClick,
    secondaryButtonTitle,
    secondaryButtonIcon,
    terciaryButtonClick,
    terciaryButtonTitle,
    terciaryButtonIcon,
    primaryButtonEndIcon,
    backButton,
  } = props;

  return (
    <styled.Root>
      <styled.PageTitle>
        <Typography variant='titleXXS'>
          <Breadcrumbs>
            {!isArray(breadcrumbs)
              ? breadcrumbs
              : breadcrumbs.map((link) => (
                  <Link key={link.label} href={link.link || '#'}>
                    <a>{link.label}</a>
                  </Link>
                ))}
          </Breadcrumbs>
        </Typography>
        <div
          onClick={() => {
            backButton &&
              router.replace(
                router.pathname.substring(0, router.pathname.lastIndexOf('/'))
              );
          }}
        >
          <Tooltip label={backButton ? 'Voltar' : null} placement='top'>
            <div>
              <Typography
                variant='titleMD'
                noWrap
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                {backButton && <LIcon icon={lineChevronLeft} size='32px' />}
                {title}
              </Typography>
            </div>
          </Tooltip>
        </div>
      </styled.PageTitle>
      <div style={{ display: 'flex' }}>
        {!!terciaryButtonTitle && (
          <>
            <Button
              data-testid={`${pathname}--pageHeader--button-${camelize(
                terciaryButtonTitle
              )}`}
              variant='dashed'
              onClick={terciaryButtonClick}
              size='md'
              style={{ marginRight: 15 }}
              startIcon={
                terciaryButtonIcon && (
                  <LIcon icon={terciaryButtonIcon} style={{ fontSize: 15 }} />
                )
              }
            >
              {terciaryButtonTitle}
            </Button>
          </>
        )}
        {!!secondaryButtonTitle && (
          <>
            <Button
              data-testid={`${pathname}--pageHeader--button-${camelize(
                secondaryButtonTitle
              )}`}
              variant='outlined'
              onClick={secondaryButtonClick}
              size='md'
              style={{ marginRight: 15 }}
              startIcon={
                secondaryButtonIcon && (
                  <LIcon icon={secondaryButtonIcon} style={{ fontSize: 15 }} />
                )
              }
            >
              {secondaryButtonTitle}
            </Button>
          </>
        )}
        {!!primaryButtonTitle && (
          <Button
            data-testid={`${pathname}--pageHeader--button-${camelize(
              primaryButtonTitle
            )}`}
            variant='contained'
            bgColor='primary'
            onClick={primaryButtonClick}
            size='md'
            endIcon={
              primaryButtonEndIcon && (
                <LIcon icon={primaryButtonEndIcon} style={{ fontSize: 15 }} />
              )
            }
            disabled={primaryButtonDisabled ?? false}
            startIcon={
              primaryButtonIcon && (
                <LIcon icon={primaryButtonIcon} style={{ fontSize: 15 }} />
              )
            }
          >
            {primaryButtonTitle}
          </Button>
        )}
      </div>
    </styled.Root>
  );
}

export default PageHeader;
