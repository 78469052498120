import React from "react";

// UI
import * as styled from "./index.style";
import { CircularProgress } from "@l_ine/core";

const Loader = () => {
  return (
    <styled.Wrapper>
      <CircularProgress />
    </styled.Wrapper>
  );
};

export default Loader;
